import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
	forkJoin,
	map,
	mergeMap,
	Subject,
	takeUntil,
	withLatestFrom,
} from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { DURATION_FORMAT_VALIDATOR } from 'src/app/core/helpers/validator-patterns.helper';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import {
	BasicCourseDetails,
	BundleCourseList,
	EditResourceContent,
	MicroLearningDetails,
	ResourceContentDetails,
	ResourceDetails,
	SearchCourseDetails,
	SelectedResource,
} from 'src/app/shared/models/create-resource';
import {
	AvailableLiveEvents,
	BundleResource,
	ContentDetails,
	CourseModule,
	CourseResourceDetails,
	CourseTest,
	EditLpDetails,
	LiveEventDetail,
	RemovingResource,
	SelectedResourceDetail,
} from '../../_models/create-resource.model';
import { CreateResourceService } from '../../_services/create-resource.service';
import { ResourceCenterService } from '../../_services/resource-center.service';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { MatDialog } from '@angular/material/dialog';
import { InactivateResourceComponent } from '../../inactivate-resource/inactivate-resource.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CourseTestService } from '../../_services/course-test.service';
import { Test } from 'src/app/shared/models/create-test';
import { ChangeCoursesStatusPayload } from 'src/app/shared/models/categories';
import { DurationFormatPipe } from 'src/app/shared/directives/duration-format.pipe';

@Component({
	selector: 'app-uploads',
	templateUrl: './uploads.component.html',
	styleUrls: ['./uploads.component.scss'],
})
export class UploadsComponent implements OnInit, OnDestroy, OnChanges {
	@Input() resourceDetails: BasicCourseDetails;
	@Input() isLearningpathway: boolean = false;
	@Input() activeStep: number = 1;
	@Output() uploadDetails: EventEmitter<{
		id: number;
		isLp: boolean;
		hasCourseTestCount?: boolean;
	}> = new EventEmitter();
	public selectedCourses: Array<SearchCourseDetails> = [];
	public activeStatus: number | null = null;
	public isSurgeUser: boolean = false;
	public lpDuration: string = '0';
	public modalOpen: boolean = false;
	public selectedType: string = '';
	public resourcesForm: FormGroup;
	public resourcesData: Array<SearchCourseDetails> = [];
	public docsData: Array<SearchCourseDetails> = [];
	public availableLiveEvents: Array<AvailableLiveEvents> = [];
	public selectedLiveEvents: Array<AvailableLiveEvents> = [];
	public selectedResources: Array<SelectedResourceDetail> = [];
	public allLiveEvents: any;
	public showMicroLearning: boolean = false;
	public acceptTypes: string = GenericHelper.uploadableTypes.join(',');
	public activeData = [
		{
			id: 1,
			name: 'Active',
		},
		{
			id: 2,
			name: 'Inactive',
		},
	];
	private unsubscriber$ = new Subject<void>();
	public isEdit = false;
	public isCopyingCourse = false;
	private copyCourseId: number = null;
	public isConvertedDoc = false;
	public courseDetails: CourseResourceDetails = <CourseResourceDetails>{};
	public lpBundleCourseList = [];
	public isClusterLocation = false;
	public sharedLocationList: string[] = [];
	public selectedSharedLocationList = new SelectionModel<string>(true);
	public isShared = false;
	public module: number = 1;
	private fromEvent: boolean = false;
	public surveyList: any;
	public surveys: any;
	public isScormContent: boolean = false;

	constructor(
		private localData: LocalDataService,
		private formBuilder: FormBuilder,
		private resourceCenterService: ResourceCenterService,
		private notificationService: NotificationService,
		private createResourceService: CreateResourceService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private router: Router,
		private el: ElementRef,
		private matDialog: MatDialog,
		private courseTestService: CourseTestService
	) {
		this.localData.castModalStatus.subscribe((res) => {
			this.modalOpen = res;
		});
	}

	ngOnInit(): void {
		this.module = +this.activatedRoute.snapshot.queryParamMap.get('module')
			? +this.activatedRoute.snapshot.queryParamMap.get('module')
			: 1;
		this.fromEvent =
			this.activatedRoute.snapshot.queryParamMap.get(
				SlrUrlQueryParams.FROM_EVENT
			) === 'true';
		this.defineForms();
		this.getMasterDetails();
		this.setEditDetails();
		this.extractSurveys();
		this.isSurgeUser = this.localData
			.getUserClasses()[0]
			.toLowerCase()
			.includes('surge');
	}
	ngOnChanges(): void {
		this.isLearningpathway &&
			this.getResourcesDocumentDetails()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: (res) => {
						this.resourcesData = res[0].sort((a, b) => {
							return a.name.localeCompare(b.name);
						});
						console.log(this.resourcesData);
						this.docsData =
							res[1]?.map((doc) => ({
								...doc,
								name: `${doc.name} ${
									doc.documentType
										? `(${doc.documentType})`
										: ``
								}`,
							})) || [];
						this.docsData?.forEach((doc) => {
							doc['type'] = 'doc';
							doc['duration'] = doc?.duration ?? 0;
						});
						this.populateSelectedResources();
						(this.isEdit || this.isCopyingCourse) &&
							this.courseDetails.learningPathBundleCourseList
								.length &&
							this.setSelectedBundleCourses(
								this.courseDetails.learningPathBundleCourseList
							);
					},
					error: () => this.notificationService.error('Api Error'),
				});
	}
	getResourcesDocumentDetails() {
		return forkJoin([
			this.resourceCenterService
				.getExcludedCourseList(this.resourceDetails?.languageId, 0)
				.pipe(takeUntil(this.unsubscriber$)),
			this.resourceCenterService
				.getExcludedDocsList(this.resourceDetails?.languageId, [])
				.pipe(takeUntil(this.unsubscriber$)),
		]);
	}
	/**
	 * Method to populate courses/ docs if user has selected from add to LP screen
	 */
	private populateSelectedResources(): void {
		const { docs, courses, events, surveys } =
			this.activatedRoute.snapshot.queryParams;
		if (docs) {
			this.setUserSelectedDocs(docs.split(',').map((id: string) => +id));
		} else if (courses) {
			this.setUserSelectedCourses(
				courses.split(',').map((id: string) => +id)
			);
		} else if (surveys) {
			this.setUserSelectedSurveys(
				surveys.split(',').map((id: string) => +id)
			);
		} else if (events) {
			this.setUserSelectedEvents([events]);
		}
		this.getLpDuration();
	}
	private setUserSelectedEvents(event: Array<number>): void {
		this.selectedLiveEvents = this.availableLiveEvents.filter(
			(events) => events.liveEventId === +event[0]
		);

		this.selectedResources = this.selectedLiveEvents;
	}
	private setUserSelectedCourses(courses: number[]): void {
		this.selectedCourses = this.resourcesData.filter((crs) =>
			courses.includes(crs.id)
		);
		this.selectedResources = this.selectedCourses;
	}
	private setUserSelectedDocs(docs: number[]): void {
		this.selectedCourses = this.docsData.filter((doc) =>
			docs.includes(doc.id)
		);
		this.selectedResources = this.selectedCourses;
	}
	private setUserSelectedSurveys(surveys: number[]): void {
		this.selectedCourses = this.surveys.filter((survey) =>
			surveys.includes(survey.id)
		);
		this.selectedResources = this.selectedCourses;
	}
	private getMasterDetails(): void {
		this.activatedRoute.data.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => {
				this.surveyList = res['masterData'][4]?.data;
				this.isClusterLocation =
					res['masterData'][1]?.sharedClientCodeList &&
					res['masterData'][1]?.sharedClientCodeList.length > 0;
				this.sharedLocationList =
					res['masterData'][1]?.sharedClientCodeList;
				const incompleteEvents = res['masterData'][2]?.filter(
					(event) =>
						event.isCompleted === 0 || event.isCompleted == null
				);
				this.allLiveEvents = res['masterData'][2]?.map(
					(event: LiveEventDetail) => {
						return {
							liveEventId: event.eventId,
							liveEventName: event.eventName,
							duration: event.duration,
						};
					}
				);
				this.availableLiveEvents = incompleteEvents?.map(
					(event: LiveEventDetail) => {
						return {
							liveEventId: event.eventId,
							liveEventName: event.eventName,
							duration: event.duration,
						};
					}
				);
			},
			error: () => this.notificationService.error('error'),
		});
	}
	private extractSurveys(): void {
		const allSurveys: any[] = [];

		this.surveyList.forEach((category) => {
			category.surveys.forEach((survey) => {
				allSurveys.push({
					...survey,
					id: survey.surveyId,
					name: survey.surveyName,
				});
			});
		});
		this.surveys = allSurveys;
		this.surveys = this.surveys.sort((a, b) =>
			a.name.localeCompare(b.name)
		);
	}
	public changeResources(
		event,
		type: 'course' | 'event' | 'docs' | 'survey'
	) {
		switch (type) {
			case 'course':
				this.setSelectedCourses(event);
				break;
			case 'docs':
				this.setSelectedCourses(event);
				break;
			case 'event':
				this.setSelectedEvents(event);
				break;
			case 'survey':
				this.setSelectedSurveys(event);
				break;
			default:
				console.error('Inavlid Type!');
		}
	}
	private setSelectedCourses(event): void {
		const selectedCourseList = this.selectedResources.filter(
			(resource) => resource?.id
		);
		if (event.length > selectedCourseList.length) {
			this.selectedResources.push(
				event.find((course) => !selectedCourseList.includes(course))
			);
		} else {
			this.selectedResources.splice(
				this.selectedResources.indexOf(
					selectedCourseList.find(
						(course) => !event?.includes(course)
					)
				),
				1
			);
		}
		this.getLpDuration();
	}
	private setSelectedSurveys(event): void {
		const selectedCourseList = this.selectedResources.filter(
			(resource) => resource?.id
		);
		if (event.length > selectedCourseList.length) {
			// this.selectedResources.push(
			// 	event.find((survey) => !selectedCourseList.includes(survey))
			// );
			const missingEvent = event.find(
				(survey) =>
					!selectedCourseList.some(
						(selectedsurvey) => selectedsurvey.id === survey.id
					)
			);

			if (missingEvent) {
				this.selectedResources.push(missingEvent);
			}
		} else {
			const elementToRemove = selectedCourseList.find(
				(selectedSurvey) =>
					!event.some((event) => event.id === selectedSurvey.id)
			);

			this.selectedResources.splice(
				this.selectedResources.indexOf(elementToRemove),
				1
			);
		}
		this.getLpDuration();
	}
	// Method to set the list of selected live events without removing the order
	private setSelectedEvents(event): void {
		const selectedEvents = this.selectedResources.filter(
			(resource) => resource?.liveEventId
		);
		if (event.length > selectedEvents.length) {
			const missingEvent = event.find(
				(liveEvent) =>
					!selectedEvents.some(
						(selectedEvent) =>
							selectedEvent.liveEventId === liveEvent.liveEventId
					)
			);
			if (missingEvent) {
				this.selectedResources.push(missingEvent);
			}
		} else {
			const elementToRemove = selectedEvents.find(
				(selectedEvent) =>
					!event.some(
						(event) =>
							event.liveEventId === selectedEvent.liveEventId
					)
			);

			this.selectedResources.splice(
				this.selectedResources.indexOf(elementToRemove),
				1
			);
		}
		this.getLpDuration();
	}
	private defineForms(): void {
		this.resourcesForm = this.formBuilder.group({
			content: this.formBuilder.array([]),
			srcDocs: this.formBuilder.array([]),
			refDocs: this.formBuilder.array([]),
			modules: this.formBuilder.array([]),
			courseDescription: [AppConstants.courseDescriptionTemplate],
			orgSpecificDesc: [''],
			newWindow: [false],
			rusticiCompletionFlag: [1],
		});
	}
	private setEditDetails(): void {
		this.isEdit = this.activatedRoute.snapshot.url
			.toString()
			.includes('edit');
		this.isCopyingCourse = !!this.activatedRoute.snapshot.queryParamMap.get(
			SlrUrlQueryParams.COPY_COURSE
		);
		if (this.isCopyingCourse) {
			this.copyCourseId = parseInt(
				this.activatedRoute.snapshot.queryParamMap.get(
					SlrUrlQueryParams.COPY_COURSE
				)
			);
		}
		this.isConvertedDoc = this.activatedRoute.snapshot.queryParamMap.get(
			'docId'
		)
			? true
			: false;

		(this.isEdit || this.isCopyingCourse) && this.getResourceDetails();
	}
	private getResourceDetails(): void {
		this.activatedRoute.data.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => {
				this.courseDetails = res['resourceDetails'][0];
				!this.isCopyingCourse && this.setSharedCodes();
				if (this.isConvertedDoc) {
					this.setDocDetails();
				}
				this.patchFormDetails(this.courseDetails);
			},
			error: (err) => console.error('error'),
		});
	}
	setDocDetails() {
		this.courseDetails.courseContentList[0].contentSection = 'content';
		this.courseDetails.courseContentList[0].fileLocation =
			this.courseDetails.courseContentList[0].fileLocation ?? 'internal';
		this.courseDetails.courseContentList[0].duration =
			this.courseDetails.courseContentList[0].duration ?? '00:00:00';
	}

	private setSharedCodes(): void {
		this.courseDetails.sharedClientCodeList &&
			this.courseDetails.sharedClientCodeList.length &&
			this.courseDetails.sharedClientCodeList.map((x) =>
				this.selectedSharedLocationList.select(x)
			);
	}

	private getEditedSharedList(): {
		sharedClientCodeList: Array<string>;
		removedSharedClientCodeList: Array<string>;
	} {
		const newAddedItems = this.selectedSharedLocationList.selected.filter(
			(selected) =>
				!this.courseDetails.sharedClientCodeList ||
				!this.courseDetails.sharedClientCodeList.includes(selected)
		);
		const removedItems = this.courseDetails.sharedClientCodeList
			? this.courseDetails.sharedClientCodeList.filter(
					(selected) =>
						!this.selectedSharedLocationList.selected.includes(
							selected
						)
			  )
			: [];

		return {
			sharedClientCodeList: newAddedItems,
			removedSharedClientCodeList: removedItems,
		};
	}

	private isSharedCourse(details: CourseResourceDetails): CourseModule {
		return details.courseModuleList.find(
			(module) => module.courseModuleId === 4
		);
	}
	private patchFormDetails(details: CourseResourceDetails): void {
		this.showMicroLearning = details.microCourseDetailsList?.length
			? true
			: false;
		this.isShared = this.isSharedCourse(details) && true;
		this.activeStatus = details.statusId;
		this.resourcesForm.patchValue({
			courseDescription: details.courseDescription,
			orgSpecificDesc: details.organizationSpecificDescription,
			newWindow: details.newWindow,
			rusticiCompletionFlag: details.rusticiCompletionFlag,
		});
		this.patchResourceContents(details);
		this.showMicroLearning && this.patchMicroLearnings(details);
	}
	private setSelectedBundleCourses(courses: Array<BundleResource>) {
		this.selectedResources = [];
		courses
			.sort((a, b) => a.lpBundleDisplayOrder - b.lpBundleDisplayOrder)
			.forEach((courseOrEvent) => {
				if (courseOrEvent.course && !courseOrEvent.document) {
					const selectedCourse = this.resourcesData?.find(
						(resource) =>
							resource.id === courseOrEvent.courseOrLiveEventId
					);
					this.selectedCourses = [
						...this.selectedCourses,
						selectedCourse,
					];
					this.selectedResources.push(selectedCourse);
				} else if (courseOrEvent.survey) {
					const selectedCourse = this.surveys.find(
						(survey) =>
							survey.surveyId ===
							courseOrEvent.courseOrLiveEventId
					);
					if (selectedCourse) {
						this.selectedCourses = [
							...this.selectedCourses,
							selectedCourse,
						];
						this.selectedResources.push(selectedCourse);
					}
				} else if (courseOrEvent.document) {
					const selectedCourse = this.docsData.find(
						(doc) =>
							doc.courseId === courseOrEvent.courseOrLiveEventId
					);
					if (selectedCourse) {
						this.selectedCourses = [
							...this.selectedCourses,
							selectedCourse,
						];
						this.selectedResources.push(selectedCourse);
					}
				} else {
					const selectedEvent = this.allLiveEvents.find(
						(resource) =>
							resource.liveEventId ===
							courseOrEvent.courseOrLiveEventId
					);
					this.selectedLiveEvents = [
						...this.selectedLiveEvents,
						selectedEvent,
					];
					this.selectedResources.push(selectedEvent);
				}
			});
		this.getLpDuration();
	}
	private patchResourceContents(details: CourseResourceDetails): void {
		['content', 'refDocs', 'srcDocs'].forEach((arr) =>
			this.getFormArray(arr).clear()
		);
		if (details.courseContentList?.length) {
			const contents = details.courseContentList;
			const resourceContents = this.returnFilteredArray(
				contents,
				'contentSection',
				'content'
			);
			const referenceContents = this.returnFilteredArray(
				contents,
				'contentSection',
				'reference'
			);
			const sourceContents = this.returnFilteredArray(
				contents,
				'contentSection',
				'source'
			);
			resourceContents.length &&
				this.getFormGroups(resourceContents, 'content');
			referenceContents.length &&
				this.getFormGroups(referenceContents, 'refDocs');
			sourceContents.length &&
				this.getFormGroups(sourceContents, 'srcDocs');

			this.isScormContent = resourceContents[0].fileLocation == 'scorm';
		}
	}
	private returnFilteredArray(array, key, expectedValue) {
		return array.filter((content) => content[key] === expectedValue);
	}
	private getFormGroups(array, formArrayName: string): void {
		array.forEach((content) => {
			this.pushToFormArray(
				formArrayName,
				this.getExistingContentFormGroup(content)
			);
		});
	}
	private patchMicroLearnings(details: CourseResourceDetails): void {
		const sortedMicroList = details.microCourseDetailsList.sort(
			(a, b) => a.microLearningDisplayOrder - b.microLearningDisplayOrder
		);
		sortedMicroList.forEach((micro, i) => {
			this.addMicroModule();
			this.patchMicroModule(micro, i);
		});
	}
	private patchMicroModule(microDetails, index: number): void {
		const microContent = microDetails.courseContent
			? microDetails.courseContent
			: null;

		this.getFormArray('modules')
			.at(index)
			.patchValue({
				id: microDetails.id,
				title: microDetails.courseName,
				interval: microDetails.interval,
				name: microContent?.contentName,
				fileType:
					microContent?.fileLocation === 'internal'
						? microContent?.contentType
						: '',
				contentId: microContent?.contentId,
				filePath:
					microContent?.fileLocation === 'internal' &&
					microContent?.filePath,
				link:
					microContent?.fileLocation === 'external' &&
					microContent?.filePath,
				duration: microContent?.duration,
				uploaded: true,
				description: microContent?.description,
			});
	}
	private getExistingContentFormGroup(
		contentDetails: ContentDetails
	): FormGroup {
		return this.formBuilder.group({
			id: contentDetails.contentId,
			file: undefined,
			uploadProgress: 0,
			uploaded: true,
			link:
				contentDetails.fileLocation === 'external' &&
				contentDetails.filePath,
			type: contentDetails.fileLocation,
			fileType:
				contentDetails.fileLocation === 'internal' ||
				contentDetails.fileLocation === 'scorm'
					? contentDetails.contentType
					: '',
			filePath:
				contentDetails.fileLocation === 'internal' ||
				contentDetails.fileLocation === 'scorm'
					? contentDetails.filePath
					: '',
			duration: [
				contentDetails.duration ?? '00:00:00',
				[Validators.pattern(DURATION_FORMAT_VALIDATOR)],
			],
			description: contentDetails.description,
			name: `${contentDetails.contentName}.${contentDetails.contentType}`,
		});
	}
	public updateFormValue(controlName: string, value: string): void {
		this.resourcesForm.get(controlName).patchValue(value);
	}
	private addMicroModule(): void {
		const microModules = <FormArray>this.resourcesForm.get('modules');
		microModules.push(
			this.formBuilder.group({
				title: ['', [Validators.required]],
				name: [''],
				fileType: [''],
				filePath: [''],
				file: [undefined],
				link: [''],
				uploaded: [false],
				uploadProgress: [0],
				duration: ['00:00:00'],
				interval: [''],
				intervalType: ['d'],
				date: [''],
				type: [''],
				description: [''],
				contentId: [0],
				id: [0],
			})
		);
	}
	private pushToFormArray(formArrayName: string, formGroup: FormGroup): void {
		this.getFormArray(formArrayName).push(formGroup);
	}
	public updateContents(event: SelectedResource) {
		this.modalOpen = false;
		this.acceptTypes = GenericHelper.uploadableTypes.join(',');
		if (typeof event.content !== 'string') {
			// Resource size check added as per requirement

			if (this.isSurgeUser) {
				if (
					GenericHelper.uploadableContentTypes.includes(
						event.content.type
					) &&
					event.content.size >
						this.localData.resourcePdfContentSizeForSurgeAdmin
				) {
					this.notificationService.error('FILE_SIZE_ERROR');
					return;
				}
				if (
					event.content.size >
					this.localData.resourceContentSizeForSurgeAdmin
				) {
					this.showContentSizeError(
						this.localData.resourceContentSizeForSurgeAdmin
					);
					return;
				}
			} else {
				if (
					GenericHelper.uploadableContentTypes.includes(
						event.content.type
					) &&
					event.content.size > this.localData.resourcePdfContentSize
				) {
					this.notificationService.error('FILE_SIZE_ERROR');
					return;
				}
				if (event.content.size > this.localData.resourceContentSize) {
					this.showContentSizeError(
						this.localData.resourceContentSize,
						true
					);
					return;
				}
			}
		}

		switch (this.selectedType) {
			case ContentType[ContentType.CONTENT]: {
				this.deleteSourceReferenceDocs(event.content);
				this.pushToFormArray(
					'content',
					this.getDocumentFormGroup(event)
				);
				break;
			}
			case ContentType[ContentType.SOURCE]:
				this.pushToFormArray(
					'srcDocs',
					this.getDocumentFormGroup(event)
				);
				break;
			case ContentType[ContentType.REFERENCE]:
				this.pushToFormArray(
					'refDocs',
					this.getDocumentFormGroup(event)
				);
				break;
			case ContentType[ContentType.MICRO]:
				this.addMicroModuleResource(event);
				break;
		}
	}

	private showContentSizeError(size, includeContactSupport = false) {
		this.translate
			.get('MAXIMUM_SIZE_MSG_T1')
			.pipe(withLatestFrom(this.translate.get('CONTACT_ADMIN')))
			.subscribe(([maxSize, contactSupport]) => {
				this.notificationService.error(
					`${maxSize.replace('T1', GenericHelper.getSize(size))} ${
						includeContactSupport && contactSupport
					}`
				);
			});
	}
	private getDocumentFormGroup({
		type,
		content,
	}: SelectedResource): FormGroup {
		this.isScormContent = type == 'scorm';
		if (type == 'scorm') {
			try {
				new URL(content as string);
				return this.returnFileObject(true, false, undefined, content);
			} catch (_) {
				return this.returnFileObject(true, false, content);
			}
		}
		if (type === 'vimeo') {
			return this.returnFileObject(false, true, content);
		}
		return type === 'file'
			? this.returnFileObject(false, false, content)
			: this.returnFileObject(false, false, undefined, content);
	}
	private returnFileObject(
		isScorm: boolean,
		isVimeo: boolean,
		file?: any,
		link?: any
	): FormGroup {
		const tempFormGroup: FormGroup = this.formBuilder.group({
			file: file,
			uploadProgress: 0,
			uploaded: file ? false : true,
			link,
			type: this.getFileObjectType(isScorm, isVimeo, file, link),
			fileType: file ? file.type : '',
			filePath: '',
			duration: [
				'00:00:00',
				[
					Validators.required,
					Validators.pattern(DURATION_FORMAT_VALIDATOR),
				],
			],
			description: '',
			name: file ? file.name : link,
		});
		return tempFormGroup;
	}

	private getFileObjectType(
		isScorm: boolean,
		isVimeo: boolean,
		file?: any,
		link?: any
	): 'scorm' | 'vimeo' | 'internal' | 'external' {
		if (isScorm) return 'scorm';
		if (isVimeo) return 'vimeo';
		if (file) return 'internal';

		if (link) {
			if (GenericHelper.isVimeoLink(link)) {
				return GenericHelper.getVimeoLinkType(link);
			}
			return 'external';
		}
		return 'internal';
	}
	private deleteSourceReferenceDocs(content): void {
		if (content.type && AppConstants.docTypes.includes(content.type)) {
			this.deleteDocs('srcDocs');
			this.deleteDocs('refDocs');
		}
	}
	private deleteDocs(formArrayName: string) {
		const formArray = this.getFormArray(formArrayName);
		let length = formArray.value.length - 1;
		while (length > -1) {
			this.deleteFile(formArrayName, 0);
			length--;
		}
	}
	public deleteFile(formArrayName: string, index: number): void {
		this.getFormArray(formArrayName).removeAt(index);
	}
	public openModal(type: number): void {
		this.selectedType = ContentType[type];
		this.localData.modalHeader = this.translate.instant('ADD_CONTENT');
		if (type && type < 2) {
			this.acceptTypes = 'application/pdf';
		}
		this.localData.updateModalStatus(true);
	}
	public onCloseModal(event: any): void {
		this.selectedType = '';
		this.localData.updateModalStatus(false);
		this.acceptTypes = GenericHelper.uploadableTypes.join(',');
	}
	public onChange(event?: any): void {
		this.activeStatus = event.value;
	}

	ngOnDestroy(): void {
		/**
		 * reset course_id in service file when a scorm course is uploaded and has no error.
		 */
		this.resourceCenterService.courseId = null;
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
	updateUploads(continueFlow: boolean = true) {
		if (this.isEdit && this.activeStatus == 2) {
			if (this.courseDetails['assigned']) {
				const dialogRef = this.matDialog.open(
					InactivateResourceComponent,
					{
						width: '40%',
						height: 'fit-content',
						data: {
							course: this.isLearningpathway
								? {
										...this.courseDetails,
										id: 0,
										learningPathwayId:
											this.courseDetails.id,
								  }
								: this.courseDetails,
							module:
								this.module === 5
									? 'DOCUMENT_MANAGER'
									: 'ALL_RESOURCES',
						},
					}
				);
				dialogRef.afterClosed().subscribe((res) => {
					if (res.success) {
						this.editResourceAPI(continueFlow);
					} else {
						// this.routeBack();
					}
				});
			} else {
				let payload: ChangeCoursesStatusPayload = {
					// check the code
					courses: this.isLearningpathway
						? []
						: [this.courseDetails?.id],
					learningPathwayIdList: this.isLearningpathway
						? [this.courseDetails?.id]
						: [],
				};

				this.resourceCenterService
					.inactivateCourses(payload)
					.subscribe({
						next: (res) => {
							this.editResourceAPI(continueFlow);
						},
						error: (err) => {
							this.notificationService.error('api error');
						},
					});
			}
		} else {
			this.editResourceAPI(continueFlow);
		}
	}
	editResourceAPI(continueFlow: boolean = true) {
		if (this.resourceCenterService.scormCourseUploading.value) {
			this.notificationService.error(
				'Uploading Scorm Course - please wait..'
			);
			return;
		}
		if (this.resourceCenterService.scormUploadError.value) {
			this.notificationService.error(
				'Scorm Content Uploaded is not valid. Please upload another course and try again.'
			);
			return;
		}
		if (this.validateInputs()) {
			this.isEdit
				? this.editResource(continueFlow)
				: this.createResource(continueFlow);
		}
		return;
	}
	private createResource(continueFlow: boolean = true): void {
		this.createResourceService
			.createResource(this.constructPayload(), this.isLearningpathway)
			.subscribe({
				next: (res) => {
					if (res['courses'][0]) {
						this.notificationService.success(
							`${
								this.isLearningpathway
									? 'Learning Pathway'
									: 'Course'
							} created successfully`
						);

						if (this.isCopyingCourse) {
							this.copyTests(continueFlow, res);
							return;
						}
						this.onActionComplete(continueFlow, res);
						return;
					}
					this.notificationService.error('Resource Exists');
				},
				error: () => this.notificationService.error('api error'),
			});
	}
	private copyTests(continueFlow, res): void {
		this.activatedRoute.data
			.pipe(
				map((data) => data['resourceDetails'][1] as Array<CourseTest>),
				map((tests) => {
					const sumOfDuration = tests.reduce((a, b) => {
						return a + this.localData.getSeconds(b.duration);
					}, 0);
					return {
						tests,
						sumOfDuration,
					};
				}),
				map(({ tests, sumOfDuration }) => {
					return {
						payload: tests.map((test) => {
							let resultTest: Test = {
								filePath: test.filePath,
								instructions: test.instructions,
								otherLocation: test.otherLocation,
								questionList: test.questionList.map(
									(question) => {
										return {
											isMandatory: question.isMandatory,
											options: question.options.map(
												(option) => {
													return {
														valueId: option.valueId,
														option: option.option,
														option_rationale:
															option.rationale,
														optionImageUrl:
															option.optionImageUrl,
														correctAnswer:
															option.correctAnswer,
														paragrahAnswer:
															option.paragraphAnswer,
													};
												}
											),
											question: question.question,
											questionDisplayOrder:
												question.questionDisplayOrder,
											questionImageUrl:
												question.questionImageUrl,
											type: question.type,
										};
									}
								),
								requiredQuestions: test.requiredQuestions,
								sequencing: test.sequencing,
								testName: test.testName,
								testType: test.testType,
							};
							return resultTest;
						}),
						sumOfDuration,
					};
				}),
				mergeMap(({ payload, sumOfDuration }) => {
					const courseId = res['courses'][0];
					let params: any = this.isLearningpathway
						? {
								learningPathwayId: courseId,
								locationId: this.localData._locationId,
								userId: this.localData.getUserId(),
								duration: sumOfDuration,
						  }
						: {
								courseId: courseId,
								locationId: this.localData._locationId,
								userId: this.localData.getUserId(),
								duration: sumOfDuration,
						  };

					return this.courseTestService.SaveCourse(
						payload,
						params,
						this.isLearningpathway
					);
				})
			)
			.subscribe({
				next: (createTestRes) => {
					this.onActionComplete(continueFlow, res);
				},
			});
	}
	private onActionComplete(continueFlow, res): void {
		if (!continueFlow) {
			this.routeBack();
			return;
		}
		this.uploadDetails.next({
			id: res['courses'][0],
			isLp: this.isLearningpathway,
			hasCourseTestCount: this.checkIfLPHasAnyTests(),
		});
	}

	private checkIfLPHasAnyTests() {
		if (!this.isLearningpathway) return undefined;
		return this.getBundledResources().some((x) => x.hasTests);
	}
	// public routeBack(): void {
	// 	this.router.navigate([this.fromEvent ? '/live-event' : '/resources'], {
	// 		queryParams: { module: this.module },
	// 	});
	// }
	routeBack() {
		if (this.fromEvent) {
			this.router.navigate(['/live-event'], {
				queryParams: { module: this.module },
			});
			return;
		}
		if (this.activatedRoute.snapshot.queryParamMap.get('reportName')) {
			let route = this.routeBackToReports(
				this.activatedRoute.snapshot.queryParamMap.get('reportName')
			);
			this.router.navigate([route]);
		} else {
			this.routeBackToRC();
		}
	}
	routeBackToRC() {
		this.router.navigate(['/resources'], {
			queryParams: {
				module: this.activatedRoute.snapshot.queryParamMap.get('module')
					? +this.activatedRoute.snapshot.queryParamMap.get('module')
					: 1,
				status: this.activatedRoute.snapshot.queryParamMap.get('status')
					? +this.activatedRoute.snapshot.queryParamMap.get('status')
					: 1,
			},
		});
	}
	routeBackToReports(reportName) {
		switch (reportName) {
			case 'REQUIRED_COMPLETION':
				return 'reports/course/required-education-completion';
			case 'REQUIRED_REPORT':
				return 'reports/course/required-report';
			case 'REQUIRED_HISTORY':
				return 'reports/course/required-history';
			case 'EDUCATION_ASSIGNED_TO_USER':
				return 'reports/user/user-assignment';
			default:
				return 'reports/course/required-education-completion';
		}
	}
	private editResource(continueFlow: boolean = true): void {
		this.createResourceService
			.editResource(this.constructPayload(), this.isLearningpathway)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					if (res instanceof Error) {
						this.notificationService.error(res.message);
						return;
					}
					this.notificationService.success(
						`${
							this.isLearningpathway
								? 'Learning Pathway'
								: 'Course'
						} updated successfully`
					);
					this.onActionComplete(continueFlow, {
						courses: [this.activatedRoute.snapshot.params['id']],
					});

					// if (!continueFlow) {
					// 	// if (
					// 	// 	this.activatedRoute.snapshot.queryParamMap.get(
					// 	// 		'fromreport'
					// 	// 	) == 'true'
					// 	// ) {
					// 	// 	this.router.navigate([
					// 	// 		'/reports/course/required-report',
					// 	// 	]);
					// 	// 	return;
					// 	// }
					// 	this.routeBack();
					// 	return;
					// }

					// this.uploadDetails.next({
					// 	id: this.activatedRoute.snapshot.params['id'],
					// 	isLp: this.isLearningpathway,
					// });
				},
				error: () => this.notificationService.error('Api Error'),
			});
	}
	private constructPayload(): ResourceDetails {
		const { value } = this.resourcesForm;
		return this.addSharedCodeListToPayload({
			...this.resourceDetails,
			locationId: this.localData.getLocationId(),
			newWindow: value.newWindow ? 1 : 0,
			rusticiCompletionFlag: value.rusticiCompletionFlag ? 1 : 0,
			courseDuration: this.getCourseDuration(),
			courseDescription: value.courseDescription,
			orgSpecificDesc: value.orgSpecificDesc,
			statusId: this.activeStatus,
			userId: this.localData.getUserId(),
			learningPathBundleCourseList: this.isLearningpathway
				? this.getBundledResources()
				: [],
			isSurgeUser: this.isSurgeUser,
			addToSharedLibrary: this.isShared,
			courseContentList: this.getCourseContents(),
			microCourseDetails: this.getMicroCourseDetails(),
			...(this.isEdit &&
				!this.isLearningpathway &&
				this.getEditPayload()),
			...(this.isEdit &&
				this.isLearningpathway &&
				this.getEditLpPayload()),
			copyCourseId: this.isCopyingCourse ? this.copyCourseId : null,
			courseOrLPId: this.resourceCenterService.courseId,
		});
	}

	private addSharedCodeListToPayload(
		payload: ResourceDetails
	): ResourceDetails {
		console.log('oldDetails -> ', this.resourceDetails);

		let res: ResourceDetails;

		if (this.isEdit) {
			// TODO: implement Edit scenario
			const editedData = this.getEditedSharedList();
			res = {
				...payload,
				sharedClientCodeList: editedData.sharedClientCodeList,
				addToSharedLibrary: editedData.sharedClientCodeList.length > 0,
				removedSharedClientCodeList:
					editedData.removedSharedClientCodeList,
			};
		} else {
			res = {
				...payload,
				sharedClientCodeList: this.selectedSharedLocationList.selected,
				addToSharedLibrary:
					this.selectedSharedLocationList.selected.length > 0,
			};
		}
		return res;
	}
	private getEditPayload(): EditResourceContent {
		return {
			courseid: this.activatedRoute.snapshot.params['id'],
			updateCourseContentsList: this.getUpdatedContents(),
			removeCourseContentsList: this.getRemovedContents(),
			microLearningList: this.getMicroCourseDetails(),
			removeFromSharedLibrary:
				this.isSharedCourse(this.courseDetails) && !this.isShared,
		};
	}
	private getEditLpPayload(): EditLpDetails {
		return {
			lpid: this.activatedRoute.snapshot.params['id'],
			addLearningPathBundleList: this.getBundledResources(
				this.getNewlyAddedResources()
			),
			removeLearningPathBundleList: this.getRemovedResources(),
			removeFromSharedLibrary:
				this.isSharedCourse(this.courseDetails) && !this.isShared,
			editLearningPathBundleList: this.getBundledResources(
				this.getUpdatedResources()
			),
		};
	}
	private getNewlyAddedResources(): Array<SelectedResourceDetail> {
		const existingResources =
			this.courseDetails.learningPathBundleCourseList;
		return this.selectedResources.filter((resource) => {
			if (resource.id && !resource.courseId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.id
					) === -1
				);
			}
			if (resource.id && resource.courseId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.courseId
					) === -1
				);
			}

			if (resource.id && resource.surveyId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.surveyId
					) === -1
				);
			}
			return (
				existingResources.findIndex(
					(rsrc) => rsrc.courseOrLiveEventId === resource.liveEventId
				) === -1
			);
		});
	}
	private getRemovedResources(): Array<RemovingResource> {
		const existingResources =
			this.courseDetails.learningPathBundleCourseList;
		return existingResources
			.filter((resource) => {
				if (resource.course && !resource.document) {
					return (
						this.selectedResources.findIndex(
							(rsrc) => rsrc.id === resource.courseOrLiveEventId
						) === -1
					);
				}
				if (resource.survey) {
					return (
						this.selectedResources.findIndex(
							(rsrc) =>
								rsrc.surveyId === resource.courseOrLiveEventId
						) === -1
					);
				}
				if (resource.course && resource.document) {
					return (
						this.selectedResources.findIndex(
							(rsrc) =>
								rsrc.courseId === resource.courseOrLiveEventId
						) === -1
					);
				}
				return (
					this.selectedResources.findIndex(
						(rsrc) =>
							rsrc.liveEventId === resource.courseOrLiveEventId
					) === -1
				);
			})
			.map((rsrc) => {
				return {
					course: rsrc.course,
					survey: rsrc.survey,
					courseOrLiveEventId: rsrc.courseOrLiveEventId,
				};
			});
	}
	private getUpdatedResources(): Array<SelectedResourceDetail> {
		const existingResources =
			this.courseDetails.learningPathBundleCourseList;
		return this.selectedResources.filter((resource) => {
			if (resource.id && !resource.courseId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.id
					) !== -1
				);
			}
			if (resource.id && resource.courseId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.courseId
					) !== -1
				);
			}
			if (resource.id && resource.surveyId) {
				return (
					existingResources.findIndex(
						(rsrc) => rsrc.courseOrLiveEventId === resource.surveyId
					) !== -1
				);
			}
			return (
				existingResources.findIndex(
					(rsrc) => rsrc.courseOrLiveEventId === resource.liveEventId
				) !== -1
			);
		});
	}
	private getCourseDuration(): number {
		const { value } = this.resourcesForm;
		let courseDuration = 0;
		const arrays = ['content', 'srcDocs', 'refDocs', 'modules'];
		arrays.forEach((type) => {
			value[type].forEach((content) => {
				courseDuration += content.duration
					? this.localData.getSeconds(content.duration)
					: 0;
			});
		});
		return courseDuration;
	}
	private getCourseContents(): Array<ResourceContentDetails> {
		const value = JSON.parse(JSON.stringify(this.resourcesForm.value));
		return [
			...value.content.map((content) => {
				return {
					...this.getFormattedContentDetails(content),
					...{ contentSection: 'content' },
				};
			}),
			...value.srcDocs.map((content) => {
				return {
					...this.getFormattedContentDetails(content),
					...{ contentSection: 'source' },
				};
			}),
			...value.refDocs.map((content) => {
				return {
					...this.getFormattedContentDetails(content),
					...{ contentSection: 'reference' },
				};
			}),
		];
	}
	private getMicroCourseDetails(): Array<MicroLearningDetails> {
		const { value } = this.resourcesForm;
		return value.modules.map((module, i) => {
			return {
				...(module.id && { id: module.id }),
				courseName: module.title,
				microLearningDisplayOrder: i + 1,
				interval: this.getIntervalDays(
					module.interval,
					module.intervalType
				),
				courseContent: {
					...this.getFormattedContentDetails(module),
					contentSection: 'content',
				},
				...(module.id && {
					removeCourseContent:
						this.getRemovedMicroContentId(module) || null,
				}),
				duration: module.duration
					? this.localData.getSeconds(module.duration)
					: 0,
			};
		});
	}
	private getRemovedMicroContentId(microResource): number | null {
		const module = this.courseDetails.microCourseDetailsList.find(
			(m) => m.id === microResource.id
		);
		if (
			module.courseContent.contentId &&
			microResource.contentId !== module.courseContent.contentId
		) {
			return module.courseContent.contentId;
		}
		return null;
	}
	private getIntervalDays(interval, type): number {
		switch (type) {
			case 'd':
				return interval;
			case 'm':
				return interval * 30;
			case 'y':
				return interval * 365;
		}
		return 0;
	}
	private getFormattedContentDetails(content): ResourceContentDetails {
		let type = '';
		if (content.name) {
			const nameCopy = content.name.split('.');
			type = nameCopy.pop();
			content.name = nameCopy.join('.');
		}
		return {
			...(content.id && { contentId: content.id }),
			contentName: content.name,
			contentType: type, // content.filePath ? type : null,
			filePath: content.filePath ? content.filePath : content.link,
			fileLocation: content.type,
			duration: content.duration,
			description: content.description,
			contentSection: '',
		};
	}
	private getRemovedContents(): Array<number> {
		const contentArrays = this.getFlattenedContents();
		return this.courseDetails.courseContentList
			.filter((content) => {
				return contentArrays.every(
					(formContent) => content.contentId !== formContent.id
				);
			})
			.map((content) => content.contentId);
	}
	private getUpdatedContents(): Array<ResourceContentDetails> {
		const courseContents = this.getCourseContents();
		const existingResources = courseContents.filter(
			(resource) => resource.contentId
		);
		const newResources = courseContents.filter(
			(resource) => !resource.contentId
		);
		const updatedResources = [];
		this.courseDetails.courseContentList.forEach((content) => {
			const updatedRsrc = existingResources.find(
				(rsrc) => rsrc.contentId === content.contentId
			);
			if (updatedRsrc) {
				updatedRsrc.duration !== content.duration &&
					updatedResources.push(updatedRsrc);
			}
		});
		return [...updatedResources, ...newResources];
	}
	private getFlattenedContents() {
		const { value } = this.resourcesForm;
		return [...value['content'], ...value['srcDocs'], ...value['refDocs']];
	}
	private getBundledResources(
		array = this.selectedResources
	): Array<BundleCourseList> {
		return array.map((resource) => {
			const resourceIndex = this.selectedResources.findIndex((rsrc) =>
				resource?.id
					? rsrc?.id === resource?.id
					: resource?.liveEventId === rsrc?.liveEventId
			);
			return {
				courseOrLiveEventId: resource?.id || resource?.liveEventId,
				lpBundleDisplayOrder: resourceIndex + 1,
				course:
					resource?.id &&
					!resource?.surveyName &&
					resource?.type !== 'doc'
						? true
						: false,
				document: resource?.type === 'doc' ? true : false,
				hasTests: resource?.hasTests,
				survey: resource?.surveyName ? true : false,
			};
		});
	}
	private validateInputs(): boolean {
		if (!this.activeStatus) {
			this.notificationService.error('Select a status for resource');
			return false;
		}
		if (
			!this.resourcesForm.value.content.length &&
			!this.isLearningpathway
		) {
			this.notificationService.error('Select a content for the resource');
			return false;
		}
		if (this.isLearningpathway && !this.selectedResources.length) {
			this.notificationService.error(
				'Select a resource to add in Learning Pathway'
			);
			return false;
		}
		if (!this.resourcesForm.get('content').valid) {
			this.notificationService.error('Please give valid duration');
			return false;
		}
		return this.resourcesForm.valid;
	}
	public dropCourse(event: CdkDragDrop<string[]>): void {
		moveItemInArray(
			this.selectedResources,
			event.previousIndex,
			event.currentIndex
		);
	}
	public removeResource(index: number, isCourse: boolean): void {
		if (isCourse) {
			const courses = [...this.selectedCourses];
			const removingCourseIndex = courses.findIndex(
				(course) => course.id === this.selectedResources[index].id
			);
			courses.splice(removingCourseIndex, 1);
			// because ng-select detects changes only if there is an assignment happens
			this.selectedCourses = [...courses];
			this.selectedResources.splice(index, 1);
		} else {
			const events = [...this.selectedLiveEvents];
			const removingEventId = events.findIndex(
				(course) =>
					course.liveEventId ===
					this.selectedResources[index].liveEventId
			);
			events.splice(removingEventId, 1);
			// because ng-select detects changes only if there is an assignment happens
			this.selectedLiveEvents = [...events];
			this.selectedResources.splice(index, 1);
		}
		this.getLpDuration();
	}
	public getFormGroup(index: number): FormGroup {
		const microModules = <FormArray>this.resourcesForm.get('modules');
		return microModules.at(index) as FormGroup;
	}
	public addMicroModuleResource({ type, content }: any): void {
		switch (type) {
			case 'link':
				this.uploadMicroResourceLink(content);
				break;
			case 'file':
				this.uploadMicroResourceFile(content);
				break;
		}
	}
	private uploadMicroResourceLink(link: string): void {
		this.addMicroModule();
		this.getFormGroup(this.getFormArrayLength() - 1).patchValue({
			link,
			uploaded: true,
			type: 'external',
		});
	}
	private uploadMicroResourceFile(file: any): void {
		this.addMicroModule();
		this.getFormGroup(this.getFormArrayLength() - 1).patchValue({
			name: file.name,
			file: file,
			fileType: file.type,
			type: 'internal',
		});
	}
	private getFormArrayLength(): number {
		return this.resourcesForm.value.modules.length;
	}
	public getFormArray(arrayName: string): FormArray {
		return <FormArray>this.resourcesForm.get(arrayName);
	}
	public getLpDuration(): void {
		this.lpDuration = `${
			this.selectedResources
				?.map((resource) => resource?.duration || 0)
				.reduce((total, duration) => +total + +duration, 0)
			// this.selectedLiveEvents
			// 	?.map((event) => event?.duration || 0)
			// 	?.reduce((total, duration) => +total + +duration, 0)
		}`;
	}
}

export enum ContentType {
	CONTENT,
	SOURCE,
	REFERENCE,
	MICRO,
}
